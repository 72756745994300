<template>
  <span>{{item.id}}</span>
</template>

<script>
export default {
name: "base-column-id"
}
</script>

<style scoped>

</style>